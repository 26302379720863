require('@rails/ujs').start()
require('@rails/activestorage').start()

import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import {} from '../store_connect/configure.js'
import {} from '../store_connect/address.js'
import {} from '../store_connect/date.js'
import {} from '../store_connect/payment_information.js'
import {} from '../store_connect/preview-switcher.js'
import {} from '../store_connect/salesforce_payments/already-paid.js'
